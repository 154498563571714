import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const query = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <QueryClientProvider client={query}>
        <App />
    </QueryClientProvider>
);
